import { useState, useEffect, useRef } from "react";
import YouTube from "react-youtube";
import type { YouTubeEvent, YouTubeProps } from "react-youtube";
import { Image } from "~/components/ui/image";
import type { ImageProps } from "~/components/ui/image";
import { useOnScreen } from "~/hooks/common";

export type Props = {
	id: string;
	children?: string;
	loading?: ImageProps["loading"];
	image?: ImageProps;
} & YouTubeProps;

export function Youtube({
	id,
	children,
	loading = "lazy",
	image,
	...rest
}: Props) {
	const [showYt, setShowYt] = useState(false);
	const [isThumbNailResAvailable, setIsThumbNailResAvailable] = useState(true);
	const imgRef = useRef<HTMLImageElement>(null);

	const ytContainerRef = useRef<HTMLDivElement>(null);
	const ytPlayerRef = useRef<ReturnType<YouTubeEvent["target"]>>(null);
	const isVisible = useOnScreen(ytContainerRef, "-100px");

	useEffect(() => {
		if (imgRef.current && imgRef.current.complete) {
			handleOnLoad();
		}
	}, [imgRef]);

	useEffect(() => {
		const yt = ytPlayerRef?.current;
		if (yt && !isVisible) {
			yt.pauseVideo();
		}
	}, [isVisible]);

	function handleOnLoad() {
		const naturalWidth = imgRef?.current?.naturalWidth || 0;
		setIsThumbNailResAvailable(naturalWidth > 120);
	}

	const handleOnReady = (e: YouTubeEvent) => {
		const ytPlayer = e.target;
		ytPlayerRef.current = ytPlayer;
		ytPlayer.playVideo();
	};

	const handleOnEnd = (e: YouTubeEvent) => {
		e.target.playVideo();
	};

	const opts: YouTubeProps["opts"] = {
		height: "100%",
		width: "100%",
		playerVars: {
			autoplay: 1,
			rel: 0,
			use_native_controls: 1,
		},
	};

	return (
		<div ref={ytContainerRef}>
			{showYt ? (
				<YouTube
					{...rest}
					className="relative z-10 aspect-video rounded-lg bg-black"
					iframeClassName="rounded-lg"
					videoId={id}
					title={children ? children : "Youtube embedded"}
					opts={opts}
					onReady={handleOnReady}
					onEnd={handleOnEnd}
				/>
			) : (
				<div
					className="group/player relative flex aspect-video cursor-pointer items-center justify-center overflow-hidden rounded-lg bg-black"
					onClick={() => setShowYt(true)}
				>
					{/* Align with Wistia player */}
					<button
						className="absolute flex h-[80px] w-[125px] items-center justify-center bg-secondary-60 transition-colors duration-150 group-hover/player:bg-secondary-40"
						aria-label={`Play video ${children}`}
					>
						<div className="h-0 w-0 border-b-[18px] border-l-[26px] border-t-[18px] border-b-transparent border-l-white border-t-transparent"></div>
					</button>
					{image?.src ? (
						<Image {...image} />
					) : (
						<Image
							src={
								isThumbNailResAvailable
									? `https://img.youtube.com/vi/${id}/maxresdefault.jpg`
									: `https://img.youtube.com/vi/${id}/maxres1.jpg`
							}
							ref={imgRef}
							onLoad={handleOnLoad}
							alt={children ? children : "Youtube thumbnail"}
							loading={loading}
						/>
					)}
				</div>
			)}
		</div>
	);
}
